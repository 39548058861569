import * as React from 'react';
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';

export function FourOhFour() {
  return (
    <Layout subPageTitle='404'>
      <PageContent style={{textAlign: 'center'}}>
          <h1>404</h1>
          <h5>
              Oh no!, it looks like we got lost in the woods again!
              <br />
              ... <a href='/'>Let's Go Home</a>
          </h5>
          <br />
          <br />
          <br />
      </PageContent>
      </Layout>
  );
}

export default FourOhFour;
